<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <!-- Modales -->
      <ng-container *ngTemplateOutlet="modalAddOrder"></ng-container>
      <div class="row">
        <ng-container *ngIf="!errorMessage;else errorAlert">
          <div class="col-12 mt-3">
            <div class="card d-print-none">
              <div class="card-header bg-dark text-white d-flex justify-content-between py-2 px-2">
                <span class="my-auto">Feed Orders</span>
                
                  <button (click)="showAddOrderModal()" class="btn btn-sm btn-success mt-0">
                    <i class="mdi mdi-plus-circle text-white"></i> Create New Order
                  </button>
              </div>
              <div class="card-body p-2">
                <table id="datatable-orders" class="table table-striped table-hover dt-responsive nowrap w-100">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Stall</th>
                      <th>Trainer</th>
                      <th>Entry</th>
                      <th>Total</th>
                      <th>Delivered</th>
                      <th></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </ng-container>
        <div style="text-align: center; width: 100%;" *ngIf="tc">
          <img style="width: 200px;" src="https://firebasestorage.googleapis.com/v0/b/tchs/o/Keyflow%20Feeds%20USA.png?alt=media&token=7fafe25d-219e-467c-be6b-0f64803db9d9" alt="">
        </div>
        <ng-template #errorAlert>
          <div class="col-12 mt-3">
            <div class="alert alert-danger" role="alert">
              <i class="ri-close-circle-line me-1 align-middle font-16"></i> <strong>Error - </strong> {{ errorMessage }}
              Please contact us and we'll link your account using the message center in the bottom right corner of the screen.
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #modalAddOrder>
  <div class="modal fade" id="addOrderModal" tabindex="-1" aria-labelledby="addOrderModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addOrderModalLabel">
            <ng-container *ngIf="selectedOrder">Edit feed order</ng-container>
            <ng-container *ngIf="!selectedOrder">New feed order</ng-container>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="orderForm">
            <div class="mb-2">
              <label for="stall" class="col-form-label">Tent/Stall:</label>
              <input type="text" class="form-control form-control-sm" id="stall" formControlName="stall">
            </div>
            <div class="mb-2">
              <label for="select-trainer" class="col-form-label">Trainer:</label>
              <select name="select-trainer" class="form-control form-control-sm" id="select-trainer"
                formControlName="trainer">
                <option value=""></option>
                <option [value]="entrenador?.idEntrenador">{{ entrenador?.nombre }}</option>
              </select>
            </div>
            <!--div class="mb-2">
                  <label for="select-entry" class="col-form-label">Entry:</label>
                  <select name="select-entry" class="form-control form-control-sm" id="select-entry" formControlName="entry">
                    <option value=""></option>
                    <option [value]="entry?.numero" *ngFor="let entry of entries">{{ entry?.numero }} - {{ entry?.nombre }}</option>
                  </select>
                </div-->
          </form>
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th><b>Concept</b></th>
                <th><b>Notes</b></th>
                <th><b>Qty</b></th>
                <th><b>Amount</b></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr [formGroup]="chargeForm">
                <td>
                  <select name="select-concept" class="form-select form-control-sm" id="select-concept"
                    (change)="updateTotal()" formControlName="concept">
                    <option value=""></option>
                    <option [value]="concept?.id" *ngFor="let concept of concepts">{{ concept?.nombre }}</option>
                  </select>
                </td>
                <td>
                  <input type="text" class="form-control form-control-sm" formControlName="notes">
                </td>
                <td>
                  <input type="number" class="form-control form-control-sm" required="" min="1"
                    formControlName="quantity" (change)="updateTotal()">
                </td>
                <td>
                  <input type="number" class="form-control form-control-sm" formControlName="total">
                </td>
                <td class="table-action text-center">
                  <a href="javascript:void(0)" class="action-icon" (click)="addCharge()">
                    <i class="mdi mdi-plus-circle-outline" style="font-size: 1.4rem;"></i>
                  </a>
                </td>
              </tr>
              <tr *ngFor="let charge of orderCharges; let index=index;">
                <td>{{ charge?.producto }}</td>
                <td>{{ charge?.notas }}</td>
                <td>{{ charge?.cantidad }}</td>
                <td>{{ charge?.linea | currency }}</td>
                <td class="table-action">
                  <!-- Opcion para borrar cargo -->
                  <a href="javascript: void(0);" class="action-icon" (click)="removeCharge(index)"> <i
                      class="mdi mdi-close"></i></a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <td colspan="3" class="text-right">
                <b>Total:</b>
              </td>
              <td colspan="2">
                {{ totalOrder | currency }}
              </td>
            </tfoot>
          </table>
          <div class="alert alert-info py-1 px-2" role="alert">
            <i class="ri-information-line me-1 align-middle font-16"></i> You will not be charged anything at this time,
            the total will be added to the trainer/entry invoice
          </div>
          <!-- <div>
            <p>
              Orders placed before 2:00pm are guaranteed same-day delivery.
            </p>
          </div> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" [disabled]="saving">Close</button>
          <button type="button" class="btn btn-primary" (click)="selectedOrder ? editOrder() : addOrder()"
            [disabled]="saving">
            <ng-container *ngIf="saving;else notSaving">
              <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
              Saving...
            </ng-container>
            <ng-template #notSaving>
              Save
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
