<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-12" *ngIf="!showID">
          <button class="button btn btn-primary" routerLink="/select-show">
            Please Select a Show
          </button>
          <br>
          <br>
        </div>
        <div class="col-md-6 col-sm-12" *ngIf="showID">
          
          <h2>{{showName}}</h2>
          <ul class="list-group">
            <h4>Entries created Online</h4>
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let draft of drafts">
              <span>
                <i class="mdi mdi-horse-human me-1"></i>
                {{ draft.horseName }}
                <span *ngIf="draft.horseUSEF">({{ draft.horseUSEF }})</span>
                <b>{{ draft.discipline == 'all' ? '' : ' - ' + draft.discipline }}</b>
              </span>
              <!-- pending approval -->
              <ng-container>
                <span>
                  <span class="badge rounded-pill text-bg-secondary">
                    {{draft.status}}
                  </span>
                  <button class="btn btn-info btn-sm ms-2" (click)="showEntryDetails(draft)">
                    <i class="mdi mdi-information"></i> Review
                  </button>
                  <a *ngIf="draft.status === 'draft'" [routerLink]="['/create-entry', draft.uid]" class="btn btn-warning btn-sm ms-2">
                    <i class="mdi mdi-pencil"></i> Edit
                  </a>
                  <a href="javascript:;" class="ms-2" [routerLink]="['/edit-entry-reining', draft.uid]"
                    routerLinkActive="router-link-active" *ngIf="reining">
                    <span class="btn btn-warning btn-sm">Edit (Reining)</span>
                  </a>
                  <a href="javascript:;" class="ms-2" [routerLink]="[checkoutRoute]" *ngIf="draft.status == 'draft' && show?.payment_gateway == 'stripe'"
                    routerLinkActive="router-link-active">
                    <span class="btn btn-success btn-sm">Pay with Card</span>
                  </a>
                  <a href="javascript:;" class="ms-2" routerLink="/betterpay-checkout" *ngIf="draft.status == 'draft' && show?.payment_gateway == 'betterpay'"
                    routerLinkActive="router-link-active">
                    <span class="btn btn-primary btn-sm"><i class="mdi mdi-credit-card"></i> Pay</span>
                  </a>
                </span>
              </ng-container>
            </li>

            <!-- <h4>Entries in Office System <small> (includes accepted online entries)</small></h4>
            <ng-container *ngFor="let entry of oldEntries">
              <li *ngIf="entry.id_concurso == showID && (entry.estatus == '1' || entry.estatus == '2')"
                class="list-group-item d-flex justify-content-between align-items-center">
                <span>
                  <i class="mdi mdi-horse-human me-1"></i>
                  <strong>
                    {{entry.entry}}
                  </strong>
                  
                  {{ entry.caballo.horseName }}
                  <span *ngIf="entry.caballo.horseUSEF">({{ entry.caballo.horseUSEF }})</span>
                 
                </span>
               
              
                <span class="badge rounded-pill" [ngClass]="{
                  'text-bg-secondary': entry.estatus == '0',
                  'bg-dark text-light': entry.estatus == '1',
                  'bg-success': entry.estatus == '2',
                  'bg-danger': entry.estatus == 'rejected'
                }">{{ entry.estatus == 1 ? 'Pending Back Number' : entry.estatus == 2 ? 'Accepted' : '' }}</span>
              </li>
            </ng-container> -->

          </ul>
        </div>

        <div class="col-xl-4 col-lg-6">
          <div *ngIf="!reining" class="card cta-box bg-primary text-white">
            <div class="card-body">
              <div class="d-flex align-items-start align-items-center">
                <div class="w-100 overflow-hidden">
                  <h2 class="mt-0 text-reset"><i class="mdi mdi-bullhorn-outline"></i>&nbsp;</h2>
                  <h3 class="m-0 fw-normal cta-box-title text-reset"></h3>
                  <p>Following payment, add/scratches can be made once the show office accepts your entry.</p>
                  <p>Draft entries can be edited.</p>
                  <p>If you need any assistance please use the message center in the bottom right corner of the screen.
                  </p>
                </div>
                <img class="ms-3" src="assets/images/svg/email-campaign.svg" width="120"
                  alt="Generic placeholder image">
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <div *ngIf="reining" class="card cta-box bg-primary text-white">
            <div class="card-body">
              <div class="d-flex align-items-start align-items-center">
                <div class="w-100 overflow-hidden">
                  <h2 class="mt-0 text-center"><i class="mdi mdi-alert-outline"></i></h2>
                  <p>Following payment, add/scratches can be made once the show office accepts your entry.</p>
                  <p>Draft entries can be edited.</p>
                  <p>If you need any assistance please contact us at <a
                      href="mailto:support&#64;100xshows.com">support&#64;100xshows.com</a>
                  </p>
                </div>
                <!-- <img class="ms-3" src="assets/images/svg/email-campaign.svg" width="120"
                  alt="Generic placeholder image"> -->
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card-->

          <!-- Todo-->


        </div>
      </div>
    </div>
  </div>
</div>

<!-- Entry Details Modal -->
<div class="modal fade" id="entryDetailsModal" tabindex="-1" role="dialog" aria-labelledby="entryDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title" id="entryDetailsModalLabel">
          <i class="mdi mdi-horse-human me-2"></i>Entry Details   - {{selectedEntry?.status}}
          <!-- Status Badge -->
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="selectedEntry">
        

        <!-- Main Information -->
        <div class="row g-3">
          <!-- Horse Info -->
          <div class="col-md-6">
            <div class="card h-100">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-horse me-2"></i>Horse Information</h6>
              </div>
              <div class="card-body py-2">
                <p class="mb-1"><strong>Name:</strong> {{selectedEntry.horseName}}</p>
                <p class="mb-1"><strong>USEF/EC Number:</strong> {{selectedEntry.horseUSEF || selectedEntry.horseEC}}</p>
                <p class="mb-0"><strong>Discipline:</strong> {{selectedEntry.discipline | titlecase}}</p>
              </div>
            </div>
          </div>

          <!-- Person Responsible -->
          <div class="col-md-6" *ngIf="selectedEntry.responsibleName">
            <div class="card h-100">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-account-check me-2"></i>Person Responsible</h6>
              </div>
              <div class="card-body py-2">
                <p class="mb-1"><strong>Name:</strong> {{selectedEntry.responsibleName}} {{selectedEntry.responsibleLastname}}</p>
                <p class="mb-0"><strong>EC Number:</strong> {{selectedEntry.responsibleEC}}</p>
              </div>
            </div>
          </div>

          <!-- Trainer Info -->
          <div class="col-md-6">
            <div class="card h-100">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-account-tie me-2"></i>Trainer Information</h6>
              </div>
              <div class="card-body py-2">
                <p class="mb-1"><strong>Name:</strong> {{selectedEntry.trainername}} {{selectedEntry.trainerLastname}}</p>
                <p class="mb-0"><strong>USEF/EC Number:</strong> {{selectedEntry.trainerUSEF || selectedEntry.trainerEC}}</p>
              </div>
            </div>
          </div>

          <!-- Owner Info -->
          <div class="col-md-6">
            <div class="card h-100">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-account me-2"></i>Owner Information</h6>
              </div>
              <div class="card-body py-2">
                <p class="mb-1"><strong>Name:</strong> {{selectedEntry.ownername}} {{selectedEntry.ownerLastname}}</p>
                <p class="mb-0"><strong>USEF/EC Number:</strong> {{selectedEntry.ownerUSEF || selectedEntry.ownerEC}}</p>
              </div>
            </div>
          </div>

          <!-- Riders Info -->
          <div class="col-12" *ngIf="selectedEntry.riders && selectedEntry.riders.length > 0">
            <div class="card">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-account-group me-2"></i>Rider Information</h6>
              </div>
              <div class="card-body py-2">
                <div class="row g-2">
                  <div class="col-md-4" *ngFor="let rider of selectedEntry.riders">
                    <div class="border rounded p-2">
                      <p class="mb-1"><strong>Name:</strong> {{rider.firstname}} {{rider.lastname}}</p>
                      <p class="mb-0"><strong>USEF/EC Number:</strong> {{rider.USEF || rider.EC}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Classes Info -->
          <div class="col-12" *ngIf="selectedEntry.pruebas && selectedEntry.pruebas.length > 0">
            <div class="card">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-trophy me-2"></i>Selected Classes</h6>
              </div>
              <div class="card-body py-2">
                <div class="table-responsive">
                  <table class="table table-sm mb-0">
                    <thead>
                      <tr>
                        <th>Class #</th>
                        <th>Class Name</th>
                        <th>Division</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let prueba of classDetails">
                        <td>{{prueba.numero_prueba}}</td>
                        <td>{{prueba.fullname}}</td>
                        <td>{{prueba.divisionData.name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Charges Info -->
          <div class="col-12" *ngIf="selectedEntry.conceptos && selectedEntry.conceptos.length > 0">
            <div class="card">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-cash-plus me-2"></i>Additional Charges</h6>
              </div>
              <div class="card-body py-2">
                <div class="table-responsive">
                  <table class="table table-sm mb-0">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th class="text-end">Amount</th>
                        <th class="text-end">Quantity</th>
                        <th class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let concepto of selectedEntry.conceptos">
                        <td>{{concepto.nombre}}</td>
                        <td class="text-end">{{concepto.monto_unitario | currency}}</td>
                        <td class="text-end">{{concepto.qty}}</td>
                        <td class="text-end">{{concepto.monto_unitario * concepto.qty | currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Payment Info -->
          <!-- <div class="col-12">
            <div class="card">
              <div class="card-header bg-light py-2">
                <h6 class="mb-0"><i class="mdi mdi-cash me-2"></i>Payment Information</h6>
              </div>
              <div class="card-body py-2">
                <div class="table-responsive">
                  <table class="table table-sm mb-0">
                    <tbody>
                      <tr>
                        <td><strong>Entry Amount:</strong></td>
                        <td class="text-end">{{selectedEntry.entryAmount | currency}}</td>
                      </tr>
                      <tr>
                        <td><strong>Additional Charges:</strong></td>
                        <td class="text-end">{{selectedEntry.totalCargos | currency}}</td>
                      </tr>
                      <tr>
                        <td><strong>Credit Card Fee ({{selectedEntry.comision_stripe * 100}}%):</strong></td>
                        <td class="text-end">{{(selectedEntry.entryAmount + selectedEntry.totalCargos) * selectedEntry.comision_stripe | currency}}</td>
                      </tr>
                      <tr class="table-primary">
                        <td><strong>Total Amount Due:</strong></td>
                        <td class="text-end"><strong>{{(selectedEntry.entryAmount + selectedEntry.totalCargos) * (1 + selectedEntry.comision_stripe) | currency}}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="text-muted small mb-0 mt-2">
                  <i class="mdi mdi-calendar-clock me-1"></i> Payment is due by {{selectedEntry.entriesDue | date}}
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          <i class="mdi mdi-close me-1"></i>Close
        </button>
      </div>
    </div>
  </div>
</div>