import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { PersonsService } from '../services/persons.service';
import { HorsesService } from '../services/horses.service';
import { EntriesService } from '../services/entries.service';
import { successNotification, errorNotification } from '../services/notifications.service';
import { SignaturePad } from 'angular-signature-pad-v2';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ShowsService } from '../services/shows.service';

declare var $: any;

@Component({
  selector: 'app-create-entry',
  templateUrl: './create-entry.component.html',
  styleUrls: ['./create-entry.component.scss']
})

export class CreateEntryComponent implements OnInit, AfterViewInit {
  @ViewChildren(SignaturePad) signaturePads: QueryList<SignaturePad>;
  public horseForm: FormGroup;
  public ownerForm: FormGroup;
  public riderForm: FormGroup;
  public trainerForm: FormGroup;
  public payeeForm: FormGroup;
  public stablingForm: FormGroup;
  public taxId: string;
  public termsAndConditions: boolean;
  public discipline: any;
  public emergencyForm: FormGroup;
  public showInfo: any;
  public show: any;
  public personLogged: any;
  public personDocument: any;
  public entry: any;
  public conceptos: any[];
  public suggestions: any;
  public selects: any;
  public elements: any;
  public comision_stripe: number;
  public entryAmountWithCCFee: number;
  public signaturePadOptions: Object;
  public conceptosSeleccionados: Array<any>;
  public entriesDue: any;
  public entryAmount: number;
  public actions: any;
  public divisions: Array<any>;
  public loadingUsef: boolean;
  public loadingFei: boolean;
  public loadingEC: boolean;
  public organizer: string;
  public reining: boolean;
  private showID: string;
  public email: string;
  public uid: string;
  public person_responsible: string;
  public responsiblePersons: any[] = [];

  // Edit mode properties
  public isEditMode: boolean = false;
  private entryId: string | null = null;

  // Add class selection properties if they are missing
  public pruebas: any[] = []; // Store filtered classes for current discipline
  public allPruebas: any[] = []; // Store all classes from all divisions
  public selectedClasses: { [key: string]: any[] } = {}; // Store selected classes per discipline
  public currentDiscipline: string = ''; // Track current discipline

  // Property to hold full details for selected riders for template binding
  public selectedRiderDetails: any[] = [];

  constructor(
    private _authService: AuthService,
    private _personsService: PersonsService,
    private _entriesService: EntriesService,
    private _horsesService: HorsesService,
    private _router: Router,
    private showsService: ShowsService,
    private route: ActivatedRoute
  ) {
    this.discipline = '';
    this.horseForm = new FormGroup({
      FEI: new FormControl('', [Validators.pattern('^[^\s]*$')]),
      USEF: new FormControl('', [Validators.pattern('^[^\s]*$')]),
      EC: new FormControl('', []),
      name: new FormControl('', [Validators.required])
    });
    this.ownerForm = new FormGroup({
      FEI: new FormControl('', []),
      USEF: new FormControl('', []),
      EC: new FormControl('', [
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(8),
        Validators.minLength(1)
      ]),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', []),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.riderForm = new FormGroup({
      FEI: new FormControl('', []),
      USEF: new FormControl('', []),
      EC: new FormControl('', [
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(8),
        Validators.minLength(1)
      ]),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.trainerForm = new FormGroup({
      USEF: new FormControl('', []),
      EC: new FormControl('', [
        Validators.pattern('^[0-9]*$'),  // Only numbers
        Validators.maxLength(8),         // Max 8 digits
        Validators.minLength(1)          // At least 1 digit
      ]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.stablingForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
    });
    this.emergencyForm = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required])
    });
    this.payeeForm = new FormGroup({
      USEF: new FormControl('', []),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', []),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.entry = {
      classes: []
    };
    this.showInfo = {};
    this.suggestions = {
      horse: [],
      trainer: [],
      owner: [],
      rider: [],
      payee: []
    }
    this.divisions = [];
    this.actions = {
      modalForm: '',
      showPayeeForm: false // Initialize showPayeeForm
    }
    this.conceptos = [];
    this.conceptosSeleccionados = [];
    this.personLogged = {};
    this.comision_stripe = 0;
    this.selects = {
      horse: '',
      trainer: '',
      owner: '',
      payee: '',
      rider: '',
      riders: []
    }
    this.termsAndConditions = false;
    this.taxId = '';
    this.signaturePadOptions = { // passed through to szimek/signature_pad constructor
      dotSize: 1,
      minWidth: 1,
      maxWidth: 1,
      backgroundColor: 'rgb(245, 245, 245)'
    };
    this.loadingUsef = false;
    this.loadingFei = false;
    this.loadingEC = false; // Initialize loadingEC
    this.showID = sessionStorage.getItem('showID')||'';
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.uid = localStorage.getItem('user_document_id')||(sessionStorage.getItem('user_document_id')||'');
    this.reining = sessionStorage.getItem('reining') === '1';
    this.person_responsible = '';
  }


  // TODO 1: Require entering at least one class for Traverse City Shows
  // TODO 2: Check inventory of additional charges selected by the user

  async ngOnInit(){
    // Check for route parameter to determine mode
    this.entryId = this.route.snapshot.paramMap.get('id');
    this.isEditMode = !!this.entryId;
    console.log('Edit Mode:', this.isEditMode, 'Entry ID:', this.entryId);

    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if(!this.uid && this.email){
      this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
        this.uid = doc.uid;
        localStorage.setItem('user_document_id', this.uid);
        console.log('User document id: ', this.uid);
        // Load suggestions only after uid is confirmed
        this.loadInitialData();
      }).catch(err => {
        console.error("Failed to get user document by email:", err);
        errorNotification('Error', 'Failed to retrieve user document. Cannot load suggestions.');
        // Proceed with loading other data even if suggestions fail
        this.loadInitialData(false); // Indicate suggestions might be missing
      });
    } else if (this.uid) {
      // If uid already exists, load data directly
      this.loadInitialData();
    } else {
      // Handle case where neither uid nor email is available
      errorNotification('Error', 'Cannot load data: User email and ID are missing.');
      this._router.navigate(['/login']); // Redirect to login or appropriate page
    }
  }

  ngAfterViewInit(): void {
    // Only subscribe to changes
    this.signaturePads.changes.subscribe(pads => {
      if (pads.length > 2) {
        this.resizeCanvas();
      }
    });
  }

  public addRider(riderId: string) {
    if (!riderId) return;
    
    console.log(`[addRider] Called with ID: ${riderId}`);
    // Log the current state of collections before changes
    console.log(`[addRider] Current state:
      - this.selects.riders: ${this.selects.riders.length} items
      - this.suggestions.rider: ${this.suggestions.rider?.length || 0} items
      - this.selectedRiderDetails: ${this.selectedRiderDetails.length} items
      - Person exists in rider suggestions: ${(this.suggestions.rider || []).some((r: any) => r.uid === riderId)}
      - Person exists in all persons: ${this.allPersons.some((p: any) => p.uid === riderId)}`);
    
    // Check if rider is already selected
    if (this.selects.riders.includes(riderId)) {
      console.log(`[addRider] Rider ${riderId} already in selects.riders. Exiting.`);
      return;
    }

    // Add the rider ID to selects.riders
    this.selects.riders.push(riderId);
    
    // Important fix: Immediately try to find the rider in all available sources
    // and add to selectedRiderDetails before any asynchronous operations
    const riderDetails = this.getRiderDetails(riderId);
    
    // Now ensure this rider is in the selectedRiderDetails array immediately
    if (riderDetails && !this.selectedRiderDetails.some((r: any) => r.uid === riderId)) {
      console.log(`[addRider] Adding rider directly to selectedRiderDetails:`, riderDetails);
      // Add to selectedRiderDetails with confirmed UID
      this.selectedRiderDetails.push({
        ...riderDetails,
        uid: riderId // Ensure UID is set correctly
      });
    }
    
    // If this is a newly added rider, refresh rider suggestions
    // This ensures we get the latest data from the database
    if (!this.suggestions.rider.some((r: any) => r.uid === riderId) && 
        !this.allPersons.some((p: any) => p.uid === riderId)) {
      console.log(`[addRider] Rider ${riderId} not found locally. Fetching from service...`);
      this._personsService.getPersonsSuggestions(this.personDocument.uid || '', 'rider')
        .then((riders: any[]) => {
          // Update our suggestions with the fresh data
          this.suggestions.rider = riders;
          
          // Filter out the already selected riders
          this.suggestions.rider = this.suggestions.rider.filter((r: any) => 
            !this.selects.riders.includes(r.uid)
          );
          
          // Update selectedRiderDetails after suggestions are refreshed
          this.updateSelectedRiderDetails();
          console.log(`[addRider] After service call:
            - this.suggestions.rider: ${this.suggestions.rider?.length || 0} items
            - Found in rider suggestions: ${this.suggestions.rider.some((r: any) => r.uid === riderId)}`);
        })
        .catch(err => {
          console.error('Error refreshing rider suggestions:', err);
        });
    } else {
    // Remove the selected rider from the suggestions dropdown
      console.log(`[addRider] Rider ${riderId} found locally. Updating lists...`);
      const beforeFilter = this.suggestions.rider.length;
    this.suggestions.rider = this.suggestions.rider.filter((r: any) => r.uid !== riderId);
      console.log(`[addRider] Filtered: ${beforeFilter} -> ${this.suggestions.rider.length} items`);
      
      // Update selectedRiderDetails immediately for UI
      this.updateSelectedRiderDetails();
    }
    
    // Reset the dropdown
    this.selects.rider = '';

    // Log once after all operations are complete
    console.log('Rider added:', {
      riderId,
      currentRiders: this.selects.riders,
      riderDetails: this.getRiderDetails(riderId)
    });
  }

  public async getShow() {
    try {
      const response = await firstValueFrom(this.showsService.getShowById(this.showID));
        if (response.error) {
        errorNotification('Error', response.error);
          return;
        }
      const showData = response.data.concurso;
      this.show = showData;
      this.entriesDue = new Date(showData.entriesDue);
      this.entryAmount = showData.entryAmount;
      this.comision_stripe = showData.comision_stripe;
      this.entryAmountWithCCFee = this.entryAmount * (1 + this.comision_stripe);
      this.organizer = showData.organizer;
      this.reining = showData.reining === 1;
    } catch (reason: any) {
      errorNotification('Error', reason);
    }
  }

  // Centralized method to load initial show/user data
  async loadInitialData(loadSuggestions = true) {
    await this.getCurrentShow(loadSuggestions);
    // Only load entry for edit *after* initial data (including suggestions if possible) is loaded
    if (this.isEditMode && this.entryId) {
      this.loadEntryForEdit(this.entryId);
    }
  }

  // Modify getCurrentShow to accept a flag for loading suggestions
  public async getCurrentShow(loadSuggestions = true) {
    if (!this.showID) {
      this._router.navigate(['/select-show']);
      return;
    }
    try {
    const response: any = await this._entriesService.getEntryInfo(this.showID);
    if (response.error) {
      errorNotification(response.error.code, response.error.message);
      return;
    }
    this.entryAmount = response.entryAmount;
    this.showInfo.entriesDue = response.entriesDue;
    this.showInfo.cardPayment = response.cardPayment;
    this.showInfo.checkPayment = response.checkPayment;
    this.comision_stripe = response.comision_stripe;
    this.entryAmountWithCCFee = this.entryAmount * (1 + this.comision_stripe);
    this.showInfo.square_config = response.square_config || null;
    this.showInfo.showID = this.showID;
    this.organizer = response.id_club;

      // Call getShow here if it's needed and not called elsewhere
      await this.getShow();

      // Load person logged info, then potentially suggestions
      await this.getPersonLogged(loadSuggestions);
      await this.getConceptos(); // Load conceptos (additional items)

    } catch (error) {
        console.error("Error in getCurrentShow:", error);
        errorNotification('Error', 'Failed to load initial show data.');
    }
  }

  // Modify getPersonLogged to accept a flag for loading suggestions
  public async getPersonLogged(loadSuggestions = true) {
    try {
    const response = await this._personsService.getByEmail(this.email, this.showInfo.showID);
    if (response.error) {
      console.log('Error getting person logged: ', response.message);
    }
    if (!response.error) this.personLogged = response.entrenador;

      // Ensure personDocument is loaded if not already available
      if (!this.personDocument && this.uid) {
    this.personDocument = await this._personsService.getDocumentByEmail(this.email);
      }

      // Load suggestions only if requested and uid is available
      if (loadSuggestions && this.personDocument?.uid) {
        await Promise.all([
          this.getHorsesSuggestions(),
          this.getPersonSuggestions('payee'),
          this.getPersonSuggestions('owner'),
          this.getPersonSuggestions('trainer'),
          this.getPersonSuggestions('rider')
        ]);
      } else if (loadSuggestions) {
          console.warn('Cannot load suggestions because user document ID (uid) is missing.');
      }

    } catch (error) {
        console.error("Error in getPersonLogged:", error);
        errorNotification('Error', 'Failed to load user data.');
    }
  }

  public async getHorsesSuggestions(select?: any) {
    if(!this.personDocument.uid||''){
      this.suggestions['horse'] = [];
      errorNotification('Not found', 'User document not found');
      return;
    }
    const suggestions = await this._horsesService.getHorsesSuggestions(this.personDocument.uid||'').catch(reason => ({ error: true, code: (reason.error||{}).message||'', message: (reason.error||{}).message||'' }));
    if (suggestions.error) {
      errorNotification(suggestions.code, `Error getting horses suggestions. ${suggestions.message}`);
      return;
    }
    this.suggestions['horse'] = suggestions||[];
    if (select) {
      this.selects.horse = select;
      $('#formModal').modal('hide');
    }
  }

  public async getPersonSuggestions(type: string, select?: any) {
    if(!this.personDocument.uid||''){
      this.suggestions[type] = [];
      errorNotification('Not found', 'User document not found');
      return;
    }
    const response: any = await this._personsService.getPersonsSuggestions(this.personDocument.uid||'', type).catch(e => e);
    
    // --- Debugging Log for Owner Suggestions ---
    if (type === 'owner') {
        console.log(`--- [${type}] Suggestions Response ---`);
        console.log('Raw response from service:', response);
    }
    // --- End Debugging Log ---

    if(response.error){
      this.suggestions[type] = [];
      errorNotification((response.error||{}).code||'', `Error getting person suggestions. ${(response.error||{}).message||''}`);
      return;
    }
    this.suggestions[type] = (response||[]).map((s: any) => ({ ...s, address: s.address || '', address2: s.address2 || '', city: s.city || '', state: s.state || '', zip: s.zip || '', country: s.country || '' }))||[];

    // --- Debugging Log for Owner Suggestions ---
    if (type === 'owner') {
        console.log('Processed suggestions assigned:', this.suggestions[type]);
        console.log(`--- End [${type}] Suggestions Response ---`);
    }
    // --- End Debugging Log ---

    if(select) {
      this.selects[type] = select;
      $('#formModal').modal('hide');
    }
  }

  private setUsefLoader(loading: boolean) {
    this.loadingUsef = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  private setFeiLoader(loading: boolean) {
    this.loadingFei = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  private setNRHALoader(loading: boolean) {
    //this.loadingNRHA = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  public getHorseByUSEF() {
    if (this.horseForm.value.USEF == '') return;
    this.setUsefLoader(true);
    this._horsesService.getHorseByUSEF(this.horseForm.value.USEF, this.showInfo.showID).subscribe(
      (response: any) => {
        if (!response.error) {
          this.horseForm.patchValue({
            FEI: response.usefData.fei || '',
            USEF: this.horseForm.value.USEF,
            EC: '',
            name: response.usefData.name || ''
          });
          this.entry.horseInfo = response.usefData;
        }
        this.setUsefLoader(false);
      },
      error => {
        this.setUsefLoader(false);
        console.warn(error)
      }
    )
  }

  public getHorseByFEI() {
    if (this.horseForm.value.FEI == '') return;
    this.setFeiLoader(true);
    this._horsesService.getHorseByFEI(this.horseForm.value.FEI).subscribe(
      (response: any) => {
        if(response.error){
          console.log('Error getting horse by FEI', response.error);
          this.setFeiLoader(false);
          return;
        }
        console.log('Horse by FEI', response.caballo);
        this.horseForm.patchValue({
          //FEI: this.horseForm.value.FEI,
          //USEF: response.caballo.usef || '',
          //EC: '',
          name: response.caballo.name || ''
        });
        this.entry.horseInfo = response.caballo;
        this.setFeiLoader(false);
      },
      error => {
        console.warn(error);
        this.setFeiLoader(false);
      }
    )
  }

  public getHorseByNRHA() {
    if (this.horseForm.value.NRHA == '') return;
    this.setNRHALoader(true);
    this._horsesService.getHorseByNrha(this.horseForm.value.NRHA).subscribe(
      (response: any) => {
        if(response.error){
          this.setNRHALoader(false);
          return;
        }
        this.horseForm.setValue({
          // FEI: this.horseForm.value.FEI,
          // USEF: this.horseForm.value.USEF,
          NRHA: this.horseForm.value.NRHA,
          name: response.caballo.name
        });
        this.entry.horseInfo = response.caballo;
        this.setNRHALoader(false);
      },
      error => {
        console.warn(error)
      }
    )
  }

  public async getPersonUsefData(usef: string, type: string) {
    if (!usef) return;
    this.setUsefLoader(true);
    try {
      const { error, message, usefData }: any = await firstValueFrom(this._personsService.getUsefData(usef));
      if (error) {
        errorNotification('Error', message);
        this.setUsefLoader(false);
        return;
      }
      const value: any = {
        USEF: usef,
        firstname: usefData.name || '',
        lastname: usefData.lastname || '',
        fei: usefData.fei || ''
      };

      if (type == 'payee') {
        //value['address'] = this.payeeForm.value.address || '';
        //value['address2'] = this.payeeForm.value.address2 || '';
        //value['city'] = this.payeeForm.value.city || '';
        //value['state'] = this.payeeForm.value.state || '';
        //value['zip'] = this.payeeForm.value.zip || '';
        //value['country'] = this.payeeForm.value.country || '';
        value['phonenumber'] = this.payeeForm.value.phonenumber || '';
        this.payeeForm.setValue(value);
        this.entry.payeeInfo = usefData;
      } else if (type == 'rider') {
        this.riderForm.patchValue(value);
        this.entry.riderInfo = value;
      } else if (type == 'owner') {
        this.ownerForm.patchValue(value);
        this.entry.ownerInfo = value;
      } else if (type == 'trainer') {
        this.trainerForm.patchValue(value);
        this.entry.trainerInfo = value;
      }
      this.setUsefLoader(false);
    } catch (reason: any) {
      errorNotification('Error', reason);
      this.setUsefLoader(false);
      return;
    }
  }

  public async getPersonECData(ec: string, type: string) {
    if (!ec || ec.length > 8 || !/^\d+$/.test(ec)) {
      errorNotification('Error', 'EC number must be between 1-8 digits');
      return;
    }
    
    this.setECLoader(true);
    
    try {
      const response = await firstValueFrom(this._personsService.getECData(ec));
      
      const ecData = response as { 
        name: string; 
        lastname: string; 
        dateOfBirth: string;
      };

      if (!ecData.name) {
        errorNotification('Error', 'EC number not found');
        return;
      }

      const value = {
        firstname: ecData.name || '',
        lastname: ecData.lastname || '',
      };

      switch (type) {
        case 'trainer':
          this.trainerForm.patchValue(value);
          this.entry.trainerInfo = value;
          break;
        case 'owner':
          this.ownerForm.patchValue(value);
          this.entry.ownerInfo = value;
          break;
        case 'rider':
          this.riderForm.patchValue(value);
          this.entry.riderInfo = value;
          break;
      }
      
    } catch (error: any) {
      console.error('Error getting EC Data:', error);
      errorNotification('Error', error.message || 'Failed to fetch EC data');
    } finally {
      this.setECLoader(false);
    }
  }

  public async getPersonByFEI() {
    if (this.riderForm.value.FEI == '') return;
    this.setFeiLoader(true);
    const response: any = await firstValueFrom(this._personsService.getPersonByFEI(this.riderForm.value.FEI));
    if (response.error){
      this.setFeiLoader(false);
      return
    }
    this.riderForm.get('firstname')?.setValue(this.riderForm.value.firstname||response.persona.name);
    this.riderForm.get('lastname')?.setValue(this.riderForm.value.lastname||response.persona.lastname);
    this.setFeiLoader(false);
  }

  public differentPayee(event: any) {
    this.actions.showPayeeForm = event.target.checked;
  }

  public async getConceptos() {
    const response: any = await this._entriesService.getConceptosNest(this.showInfo.showID);
    if (response.error) {
      errorNotification('Error', response.message);
      return;
    }
    this.conceptos = response.data;
    console.log('Conceptos: ', this.conceptos);
  }

  public getDivisionsByType() {
    this.divisions = [];
    if (this.discipline == '' || this.discipline == 'Non_showing') return;
    const discipline = this.discipline == 'FEI_jumpers' ? 'jumpers' : this.discipline;
    console.log("Discipline: ", discipline);
    this._entriesService.getDivisionsByType(this.showInfo.showID, discipline).subscribe(
      response => {
        if(this.discipline == 'FEI_jumpers'){
          this.divisions = response.divisions.filter((division: any) => division.name.includes('FEI'));
          console.log('FEI Divisions: ', this.divisions);
        } else if (this.discipline == 'jumpers'){
          this.divisions = response.divisions.filter((division: any) => !division.name.includes('FEI'));
          console.log('Jumper Divisions: ', this.divisions);
        } else {
          this.divisions = response.divisions;
          console.log('Other Divisions: ', this.divisions);
        }
      },
      error => {
        console.warn(error)
      }
    )
  }

  /**
   * Add or update a charge to the selected concepts list
   */
  public agregarCargo(idConcepto: any, monto_unitario: any, nombre: any, event: any) {
    // Ensure conceptosSeleccionados is initialized
    if (!this.conceptosSeleccionados) {
      this.conceptosSeleccionados = [];
    }

    console.log("Conceptos seleccionados: ", this.conceptosSeleccionados);
    
    const qty = isNaN(event.target.valueAsNumber) ? 0 : event.target.valueAsNumber;
    const index = this.conceptosSeleccionados.findIndex(concepto => {
      return concepto.idConcepto == idConcepto;
    });
    
    if (qty == 0) { //Si es 0 borrar de los conceptos seleccionados
      if (index > -1) { //Ya existe en el array conceptos seleccionados
        this.conceptosSeleccionados.splice(index, 1);
      } else {
        //No existe entonces no hay que borrar nada
      }
    } else {
      if (index > -1) { //Ya existe en el array conceptos seleccionados hay que editarlo
        this.conceptosSeleccionados[index].qty = qty;
        this.conceptosSeleccionados[index].total = monto_unitario * qty;
      } else { //Aun no existe en el array hay que agregarlo
        this.conceptosSeleccionados.push({ idConcepto, monto_unitario, nombre, qty, total: monto_unitario * qty });
      }
    }
    
    // Force change detection
    this.conceptosSeleccionados = [...this.conceptosSeleccionados];
  }

  /**
   * Calculate the total amount for all selected additional items
   * @returns The total amount or 0 if no items are selected
   */
  public totalCargos(): number {
    if (!this.conceptosSeleccionados || this.conceptosSeleccionados.length === 0) {
      return 0;
    }
    return this.conceptosSeleccionados.reduce((a, b) => {
      return a + (b.total || 0);
    }, 0);
  }

  public addClassToEntry(ipc: any) {
    console.warn('addClassToEntry called - should ideally use toggleClassSelection');
    const classObject = this.pruebas.find(p => p.ipc === ipc);
    if (classObject) {
        this.toggleClassSelection(classObject);
    }
  }

  public checkClassInEntry(ipc: any): boolean {
      return this.entry.classes?.includes(ipc) || false;
  }

  public async save() {
    if (!this.validateForm()) {
      console.log('Form validation failed.');
      return;
    }

    const signatureTrainerSvg = this.getSignature('trainer');
    const signatureOwnerSvg = this.getSignature('owner');
    const signatureResponsibleSvg = this.getSignature('responsible');

    // Re-validate signatures specifically for save/update
    if (!signatureTrainerSvg || !signatureOwnerSvg || (this.show?.organizing_country === 'CAN' && this.person_responsible && !signatureResponsibleSvg)) {
      errorNotification('Error', 'Please sign all required signature fields before saving.');
      return;
    }

    // Compile document data
    const horseDetails = this.getHorseDetails(this.suggestions.horse.find((e: any) => e.uid == this.selects.horse));
    const ownerDetails = this.getPersonDetails(this.selects.owner);
    const trainerDetails = this.getPersonDetails(this.selects.trainer);
    const payeeDetails = this.actions.showPayeeForm ? this.getPersonDetails(this.selects.payee) : null;
    const responsibleDetails = this.person_responsible ? this.getPersonDetails(this.person_responsible) : null;

    const document = {
      // IDs for relationships
      horse: this.selects.horse || '',
      owner: this.selects.owner || '',
      trainer: this.selects.trainer || '',
      payee: this.selects.payee || '',
      person_responsible: this.person_responsible || '',

      // Rider details (store array of objects with uid and basic info)
      riders: this.selects.riders.map((riderId: any) => {
          const details = this.getRiderDetails(riderId);
          return {
              uid: riderId,
              USEF: details?.USEF || '',
              FEI: details?.FEI || '',
              EC: details?.EC || '',
              firstname: details?.firstname || '',
              lastname: details?.lastname || '',
              // Include other necessary rider fields if needed elsewhere
          };
      }),

      // Denormalized fields (keep for compatibility/display if needed, but primary link is ID)
      horseName: horseDetails?.name || '',
      horseUSEF: horseDetails?.USEF || '',
      horseFEI: horseDetails?.FEI || '',
      horseEC: horseDetails?.EC || '',
      ownername: ownerDetails?.firstname || '',
      ownerLastname: ownerDetails?.lastname || '',
      ownerUSEF: ownerDetails?.USEF || '',
      ownerFEI: ownerDetails?.FEI || '',
      ownerEC: ownerDetails?.EC || '',
      trainername: trainerDetails?.firstname || '',
      trainerLastname: trainerDetails?.lastname || '',
      trainerUSEF: trainerDetails?.USEF || '',
      trainerEC: trainerDetails?.EC || '',
      // Payee denormalized fields (use owner as fallback only if payee not selected/different)
      payeename: payeeDetails?.firstname || (this.actions.showPayeeForm ? '' : ownerDetails?.firstname || ''),
      payeeLastname: payeeDetails?.lastname || (this.actions.showPayeeForm ? '' : ownerDetails?.lastname || ''),
      payeeUSEF: payeeDetails?.USEF || (this.actions.showPayeeForm ? '' : ownerDetails?.USEF || ''),
      payeeEC: payeeDetails?.EC || (this.actions.showPayeeForm ? '' : ownerDetails?.EC || ''),
      // Responsible denormalized fields
      responsibleName: responsibleDetails?.firstname || '',
      responsibleLastname: responsibleDetails?.lastname || '',
      responsibleEC: responsibleDetails?.EC || '',

      // Other form fields
      emergencyContact: this.emergencyForm.value.fullname || '',
      emergencyPhone: this.emergencyForm.value.phone || '',
      stablingWith: `${this.stablingForm.value.name || ''} ${this.stablingForm.value.lastname || ''}`.trim(),
      discipline: this.discipline || '',
      taxID: this.taxId || '',
      termsAndConditions: this.termsAndConditions,

      // Structured data
      conceptos: this.conceptosSeleccionados || [],
      pruebas: (this.entry?.classes || []).map((c: any) => c.ipc) || [],

      // Metadata
      email: this.email || '',
      showID: this.showID || '',
      status: 'draft',
      paymentReceived: false,
      // createdAt: this.isEditMode && this.entry.createdAt ? this.entry.createdAt : new Date(), // Requires entry to hold createdAt
      updatedAt: new Date(),

      // Signatures
      signatureTrainer: signatureTrainerSvg || '',
      signatureOwner: signatureOwnerSvg || '',
      signatureResponsible: signatureResponsibleSvg || ''
    };

    // Clean up undefined/null values potentially?
    // Object.keys(document).forEach(key => (document[key] === undefined || document[key] === null) && delete document[key]);

    console.log('Document to save/update:', document);

    try {
      let response: any;
      if (this.isEditMode && this.entryId) {
        // Call update method (to be created in EntriesService)
        console.log('Attempting to update entry:', this.entryId);
        response = await this._entriesService.updateEntryFirebase(this.entryId, document, this.uid);
      } else {
        // Call save method
        console.log('Attempting to save new entry:');
        response = await this._entriesService.saveEntryFirebase(document, this.uid);
      }

      if (response.error) {
        errorNotification(response.error.code, response.error);
      return;
    }

      // Reset form state only on successful save/update
      this.resetFormState();

      this._router.navigate(['/']);
      successNotification(this.isEditMode ? 'Entry Updated' : 'Registration saved', this.isEditMode ? 'Your entry has been updated successfully' : 'Your registration has been saved successfully');

    } catch (error: any) {
      console.error("Error saving/updating entry:", error);
      errorNotification('Error', `Failed to ${this.isEditMode ? 'update' : 'save'} entry.`);
    }
  }

  // Helper method to reset form state
  private resetFormState() {
      console.log('Resetting form state...');
      // Clear signature pads
      if (this.signaturePads) {
        for(const pad of this.signaturePads.toArray()){
            pad.clear();
        }
      }
      // Reset forms
      this.horseForm.reset();
      this.ownerForm.reset();
      this.riderForm.reset();
      this.trainerForm.reset();
      this.stablingForm.reset();
      this.emergencyForm.reset();
      this.payeeForm.reset();
      // Reset selects object
      this.selects = {
        horse: '',
        trainer: '',
        owner: '',
        payee: '',
        rider: '',
        riders: []
      };
      // Reset entry object and related arrays
      this.entry = { classes: [] };
      this.conceptosSeleccionados = [];
      this.discipline = '';
      this.divisions = [];
      this.pruebas = [];
      this.selectedClasses = {};
      this.allPruebas = []; // Resetting allPruebas might require re-fetch if user navigates back
      // Reset flags and IDs
      this.termsAndConditions = false;
      this.taxId = '';
      this.person_responsible = '';
      this.actions.showPayeeForm = false;
      this.isEditMode = false;
      this.entryId = null;
      // Optionally clear suggestions if they should be re-fetched
      // this.suggestions = { horse: [], trainer: [], owner: [], rider: [], payee: [] };
      console.log('Form state reset complete.');

      // Update rider details after resetting selects.riders
      this.updateSelectedRiderDetails();
  }

  public async loadEntryForEdit(entryId: string) {
    console.log(`Loading entry ${entryId} for edit...`);
    try {
      // Assuming EntriesService will have a method getEntryById
      const entryData = await this._entriesService.getEntryById(entryId);
      if (!entryData) {
        errorNotification('Error', `Entry with ID ${entryId} not found.`);
        this._router.navigate(['/']); // Navigate away if entry not found
        return;
      }
      
      console.log('Fetched entry data:', entryData);

      // Populate form fields
      this.entry = { ...entryData }; // Use fetched data as the base, create a copy


      // --- Set Selects with Fallback Logic ---
      let trainerId = entryData.trainer;
      // Search ALL PERSONS for trainer fallback
      if (!trainerId && entryData.trainerUSEF && this.allPersons?.length > 0) {
        const found = this.allPersons.find((p: any) => p.USEF === entryData.trainerUSEF);
        if (found) { trainerId = found.uid; console.log('Found trainer via USEF in allPersons'); }
      } else if (!trainerId && entryData.trainerEC && this.allPersons?.length > 0) {
        const found = this.allPersons.find((p: any) => p.EC === entryData.trainerEC);
        if (found) { trainerId = found.uid; console.log('Found trainer via EC in allPersons'); }
      } else if (!trainerId && entryData.trainername && this.allPersons?.length > 0) { // Fallback to name
        const found = this.allPersons.find((p: any) => p.firstname === entryData.trainername && p.lastname === entryData.trainerLastname);
        if (found) { trainerId = found.uid; console.log('Found trainer via Name in allPersons'); }
      }
      this.selects.trainer = trainerId || '';

      let horseId = entryData.horse;
      // Keep searching horse suggestions for horse fallback
      if (!horseId && entryData.horseUSEF && this.suggestions.horse?.length > 0) {
        const found = this.suggestions.horse.find((h: any) => h.USEF === entryData.horseUSEF);
        if (found) { horseId = found.uid; console.log('Found horse via USEF'); }
      } else if (!horseId && entryData.horseEC && this.suggestions.horse?.length > 0) {
        const found = this.suggestions.horse.find((h: any) => h.EC === entryData.horseEC);
        if (found) { horseId = found.uid; console.log('Found horse via EC'); }
      } else if (!horseId && entryData.horseName && this.suggestions.horse?.length > 0) { // Fallback to name if numbers missing
        const found = this.suggestions.horse.find((h: any) => h.name === entryData.horseName);
        if (found) { horseId = found.uid; console.log('Found horse via Name'); }
      }
      this.selects.horse = horseId || '';

      let ownerId = entryData.owner;
      // Search ALL PERSONS for owner fallback
      if (!ownerId && entryData.ownerUSEF && this.allPersons?.length > 0) {
        const found = this.allPersons.find((p: any) => p.USEF === entryData.ownerUSEF);
        if (found) { ownerId = found.uid; console.log('Found owner via USEF in allPersons'); }
      } else if (!ownerId && entryData.ownerEC && this.allPersons?.length > 0) {
        const found = this.allPersons.find((p: any) => p.EC === entryData.ownerEC);
        if (found) { ownerId = found.uid; console.log('Found owner via EC in allPersons'); }
      } else if (!ownerId && entryData.ownername && this.allPersons?.length > 0) { // Fallback to name
        const found = this.allPersons.find((p: any) => p.firstname === entryData.ownername && p.lastname === entryData.ownerLastname);
        if (found) { ownerId = found.uid; console.log('Found owner via Name in allPersons'); }
      }
       this.selects.owner = ownerId || '';
      // --- End Set Selects ---

      this.selects.riders = entryData.riders ? entryData.riders.map((r: any) => r.uid || r) : [];
      this.selects.payee = entryData.payee || '';
      this.actions.showPayeeForm = !!entryData.payee;
      this.taxId = entryData.taxID || '';
      this.person_responsible = entryData.person_responsible || '';
      this.emergencyForm.patchValue({
        fullname: entryData.emergencyContact || '',
        phone: entryData.emergencyPhone || ''
      });
      // Ensure stablingWith exists before splitting
      const stablingNameParts = entryData.stablingWith?.split(' ') || [];
      this.stablingForm.patchValue({
          name: stablingNameParts[0] || '',
          lastname: stablingNameParts.slice(1).join(' ') || '' // Handle names with multiple parts
      });
      this.termsAndConditions = entryData.termsAndConditions || false;
      this.conceptosSeleccionados = entryData.conceptos || [];

      // Load classes
      console.log('Loading classes for entry:', entryData.pruebas);
      this.entry.classes = entryData.pruebas;

      // Discipline and Classes need careful handling
      this.discipline = entryData.discipline || '';
      if (this.discipline && this.discipline !== 'Non_showing') {
        this.getDivisionsByType();
        this.updateSelectedClasses(); // Ensure entry.classes is updated initially
      }

      // Update rider details after loading selects.riders
      this.updateSelectedRiderDetails();

      console.log('Form populated for edit.');

    } catch (error) {
      console.error(`Error loading entry ${entryId}:`, error);
      errorNotification('Error', 'Failed to load entry data for editing.');
      this._router.navigate(['/']); // Navigate away on error
    }
  }

  showModal() { $('#agreementModal').modal('show'); }

  hideModal() { $('#agreementModal').modal('hide'); }

  resizeCanvas() {
    const pads = this.signaturePads?.toArray() || [];
    if (pads.length === 2) return;

    const offsetWidth = document.querySelector("signature-pad")?.parentElement?.offsetWidth||0;
    for(const sinaturePad of this.signaturePads.toArray() as any[]){
      sinaturePad.elementRef.nativeElement.querySelector("canvas").width = offsetWidth;
      sinaturePad.clear();
    } 
  }

  public getAddress(place: any, entity: string) {
    if (entity == 'rider') {
      if (!this.riderForm.value.address) this.riderForm.get('address')?.setValue(place.address);
      if (!this.riderForm.value.address2) this.riderForm.get('address2')?.setValue(place.address2);
      if (!this.riderForm.value.city) this.riderForm.get('city')?.setValue(place.city);
      if (!this.riderForm.value.state) this.riderForm.get('state')?.setValue(place.state);
      if (!this.riderForm.value.zip) this.riderForm.get('zip')?.setValue(place.zip);
      if (!this.riderForm.value.country) this.riderForm.get('country')?.setValue(place.country);
    } else if (entity == 'trainer') {
      if (!this.trainerForm.value.address) this.trainerForm.get('address')?.setValue(place.address);
      if (!this.trainerForm.value.address2) this.trainerForm.get('address2')?.setValue(place.address2);
      if (!this.trainerForm.value.city) this.trainerForm.get('city')?.setValue(place.city);
      if (!this.trainerForm.value.state) this.trainerForm.get('state')?.setValue(place.state);
      if (!this.trainerForm.value.zip) this.trainerForm.get('zip')?.setValue(place.zip);
      if (!this.trainerForm.value.country) this.trainerForm.get('country')?.setValue(place.country);
    } else if (entity == 'owner') {
      if (!this.ownerForm.value.address) this.ownerForm.get('address')?.setValue(place.address);
      if (!this.ownerForm.value.address2) this.ownerForm.get('address2')?.setValue(place.address2);
      if (!this.ownerForm.value.city) this.ownerForm.get('city')?.setValue(place.city);
      if (!this.ownerForm.value.state) this.ownerForm.get('state')?.setValue(place.state);
      if (!this.ownerForm.value.zip) this.ownerForm.get('zip')?.setValue(place.zip);
      if (!this.ownerForm.value.country) this.ownerForm.get('country')?.setValue(place.country);
    } else if (entity == 'payee') {
      if (!this.payeeForm.value.address) this.payeeForm.get('address')?.setValue(place.address);
      if (!this.payeeForm.value.address2) this.payeeForm.get('address2')?.setValue(place.address2);
      if (!this.payeeForm.value.city) this.payeeForm.get('city')?.setValue(place.city);
      if (!this.payeeForm.value.state) this.payeeForm.get('state')?.setValue(place.state);
      if (!this.payeeForm.value.zip) this.payeeForm.get('zip')?.setValue(place.zip);
      if (!this.payeeForm.value.country) this.payeeForm.get('country')?.setValue(place.country);
    }
  }

  public setECLoader(loading: boolean) {
    this.loadingEC = loading;
  }

  public async getHorseECData(ec: string) {
    if (!ec || ec.length > 8 || !/^\d+$/.test(ec)) {
      errorNotification('Error', 'EC number must be between 1-8 digits');
        return;
      }
    
    try {
      const response = await firstValueFrom(this._horsesService.getHorseECData(ec));
      
      const ecData = response as { 
        name: string;
        // Add other expected horse data fields here
      };

      if (!ecData.name) {
        errorNotification('Error', 'Horse EC number not found');
        return;
      }

      this.horseForm.patchValue({
        name: ecData.name
        // Add other fields as needed
      });
      
    } catch (error: any) {
      console.error('Error getting horse EC data:', error);
      errorNotification('Error', error.message || 'Failed to fetch horse EC data');
    }
  }

  get allPersons() {
    const allPersons = [
      ...this.suggestions.trainer,
      ...this.suggestions.rider, 
      ...this.suggestions.owner
    ];
    
    // Remove duplicates by USEF number, fallback to uid if no USEF
    return Array.from(
      new Map(
        allPersons.map(p => [p.USEF || p.uid, p])
      ).values()
    );
  }

  public getRiderDetails(riderId: string) {
    console.log(`[getRiderDetails] Called for ID: ${riderId}`); // Log input ID

    // Exit early if no rider ID provided
    if (!riderId) {
      console.warn('[getRiderDetails] No rider ID provided');
      return {
        firstname: "",
        lastname: "",
        USEF: "",
        EC: ""
      };
    }

    // First try to find in combined suggestions
    const localRiderSuggestions = this.suggestions.rider || [];
    const ownerSuggestions = this.suggestions.owner || [];
    const trainerSuggestions = this.suggestions.trainer || [];
    
    // Log each collection separately for debugging
    console.log(`[getRiderDetails] Collections:
      - riders: ${localRiderSuggestions.length} items
      - owners: ${ownerSuggestions.length} items
      - trainers: ${trainerSuggestions.length} items
      - selectedRiderDetails: ${this.selectedRiderDetails.length} items`);
    
    // For suspicious rider ID, dump the first item for inspection
    if (localRiderSuggestions.length > 0 && !localRiderSuggestions.some((r: any) => r.uid === riderId)) {
      console.log(`[getRiderDetails] Rider ${riderId} not found in rider suggestions. First item in collection:`, 
        localRiderSuggestions[0]);
    }
    
    const allRiderSuggestions = [
      ...localRiderSuggestions,
      ...ownerSuggestions,
      ...trainerSuggestions
    ];
    const uniqueRiderSuggestions = Array.from(new Map(allRiderSuggestions.map(p => [p.uid, p])).values());
    
    // Check selectedRiderDetails first - this should have the most up-to-date info
    let rider = this.selectedRiderDetails.find((r: any) => r.uid === riderId);
    if (rider) {
      console.log(`[getRiderDetails] Found in selectedRiderDetails:`, rider);
      return rider;
    }
    
    // Then check suggestions
    console.log(`[getRiderDetails] Searching in combined suggestions (${uniqueRiderSuggestions.length} riders)`);
    rider = uniqueRiderSuggestions.find(person => person.uid === riderId);
    if (rider) {
      console.log(`[getRiderDetails] Found in suggestions:`, rider);
      
      // Check if this rider is from riderSuggestions but not in selectedRiderDetails
      const isFromRiderSuggestions = localRiderSuggestions.some((r: any) => r.uid === riderId);
      console.log(`[getRiderDetails] Is from rider suggestions: ${isFromRiderSuggestions}`);
      
      // If it's in suggestions but not in selectedRiderDetails, make sure we add it
      if (!this.selectedRiderDetails.some((r: any) => r.uid === riderId)) {
        console.log(`[getRiderDetails] IMPORTANT: Rider found in suggestions but not in selectedRiderDetails. This may be the bug.`);
      }
      
      return rider; // Found in suggestions
    }

    // If not found, check if it's in the loaded entry data (during edit mode)
    console.log(`[getRiderDetails] Not found in suggestions. Checking entry data (Edit mode: ${this.isEditMode})`);
    if (this.isEditMode && this.entry?.riders) {
      console.log(`[getRiderDetails] Searching in loaded entry.riders:`, this.entry.riders);
      // Ensure the check handles cases where entry.riders might store just IDs or full objects
      rider = this.entry.riders.find((r: any) => (r.uid || r) === riderId); 
      if (rider) {
        console.log(`[getRiderDetails] Found in loaded entry.riders:`, rider);
        return rider; // Found in loaded entry data
      }
    }

    // Fallback
    console.warn(`[getRiderDetails] RIDER NOT FOUND: ${riderId} in any collection`);
    console.log('Full rider suggestions:', localRiderSuggestions);
    return { // Returns placeholder
      firstname: "Not Found",
      lastname: "",
      USEF: "",
      EC: "",
      uid: riderId // Include the UID to help with debugging
    };
  }

  public getPersonDetails(personId: string): any | null {
      // Immediately return null if personId is falsy (null, undefined, '')
      // This prevents warnings/errors when initially bound in the template
      if (!personId) {
          return null;
      }

      const allPersons = [
        ...(this.suggestions.trainer || []),
        ...(this.suggestions.rider || []),
        ...(this.suggestions.owner || []),
        ...(this.suggestions.payee || [])
      ];
      // Deduplicate based on uid
      const uniquePersons = Array.from(new Map(allPersons.map(p => [p.uid, p])).values());
      let person = uniquePersons.find(p => p.uid === personId);

      if (person) {
          return person;
      }

      // If not found, check if it's in the loaded entry data (during edit mode)
      if (this.isEditMode && this.entry) {
          // Check if the personId matches stored IDs and return corresponding details if available
          if (this.entry.owner === personId && this.entry.ownerInfo) return { uid: personId, ...this.entry.ownerInfo };
          if (this.entry.trainer === personId && this.entry.trainerInfo) return { uid: personId, ...this.entry.trainerInfo };
          if (this.entry.payee === personId && this.entry.payeeInfo) return { uid: personId, ...this.entry.payeeInfo };
          if (this.entry.person_responsible === personId && this.entry.responsibleInfo) return { uid: personId, ...this.entry.responsibleInfo }; // Check responsible person too
      }

      // If still not found after all checks, log warning and return a placeholder
      console.warn('Person details not found locally for ID:', personId);
      return { firstname: "Not Found", lastname: "", USEF: "", EC: "" }; // Return placeholder object
  }

  private getSignature(entity: string) {
    const signaturePad = this.signaturePads.toArray().find((sp: any) => sp.elementRef.nativeElement.classList.contains(entity));
    if (signaturePad) {
      return atob((signaturePad.toDataURL('image/svg+xml')||'').replace('data:image/svg+xml;base64,', ''));
    }
    return null;
  }

  private getHorseDetails(horse: any) {
    return {
      USEF: horse.USEF || '',
      FEI: horse.FEI || '',
      EC: horse.EC || '',
      name: horse.name || '',
    };
  }

  private validateForm(): boolean {
    if (this.selects.trainer == '') {
      errorNotification('Error', 'Trainer has not been selected');
      return false;
    }
    if (this.selects.horse == '') {
      errorNotification('Error', 'horse has not been selected');
      return false;
    }
    if (this.selects.owner == '') {
      errorNotification('Error', 'Owner has not been selected');
      return false;
    }
    if (this.selects.riders.length == 0) {
      errorNotification('Error', 'Rider has not been selected');
      return false;
    }
    if (this.selects.payee == '' && this.actions.showPayeeForm) {
      errorNotification('Error', 'payee has not been selected');
      return false;
    }
    if (!this.emergencyForm.valid) {
      errorNotification('Error', 'Emergency form incomplete');
      return false;
    }
    if (this.discipline == '') {
      errorNotification('Error', 'Discipline field empty');
      return false;
    }
    if(!this.stablingForm.valid){
      errorNotification('Error', 'Stabling with form incomplete');
      return false;
    }
      
    // Check if class selection is required for this show (club_id === 565)
    if (this.show?.id_club === 562 && (!this.entry.classes || this.entry.classes.length === 0)) {
      errorNotification('Error', 'Please select at least one class for this entry');
      return false;
    }
      
      if (!this.termsAndConditions) {
        errorNotification('Error', 'Terms and Conditions checkbox not selected');
      return false;
      }
      
        //Validar que las firmas esten completas
    let signaturesValid = true
    for(const pad of this.signaturePads.toArray()){
      if(pad.isEmpty()) signaturesValid = false;
    }
    if (!signaturesValid) {
      errorNotification('Error', 'A signature is missing');
      return false;
    }

    return true;
  }

  public removeSpaces(event: any, field: string) {
    const input = event.target;
    const value = input.value.replace(/\s/g, '');
    input.value = value;
    this.horseForm.get(field)?.setValue(value);
  }

  // Re-add missing methods previously removed
  public async saveSuggestions(e: any) {
    const address1 = document.querySelector('#address1') as HTMLInputElement;
    e.target.classList.add('was-validated');
    if (this.actions.modalForm == 'horse') {
      const horse = {
        USEF: this.horseForm.value.USEF || '',
        FEI: this.horseForm.value.FEI || '',
        EC: this.horseForm.value.EC || '',
        name: this.horseForm.value.name || '',
      };
      if (horse.USEF) {
        const horseSuggestion = this.suggestions['horse'].find((h: any) => horse.USEF == h.USEF);
        if (horseSuggestion) {
          this.getHorsesSuggestions(horseSuggestion.uid);
          return;
        }
      }

      if (!this.horseForm.valid) return;

      const { documentId, reason } = await this._horsesService.setHorseSuggestion(this.uid, horse).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getHorsesSuggestions(documentId);

    } else if (this.actions.modalForm == 'owner') {
      //this.ownerForm.get('address')?.setValue(address1.value);
      const owner = {
        USEF: this.ownerForm.value.USEF || '',
        FEI: this.ownerForm.value.FEI || '',
        firstname: this.ownerForm.value.firstname || '',
        lastname: this.ownerForm.value.lastname || '',
        address: this.ownerForm.value.address || '',
        address2: this.ownerForm.value.address2 || '',
        phonenumber: this.ownerForm.value.phonenumber || '',
        city: this.ownerForm.value.city || '',
        state: this.ownerForm.value.state || '',
        zip: this.ownerForm.value.zip || '',
        country: this.ownerForm.value.country || '',
        EC: this.ownerForm.value.EC || '',
      };
      if (owner.USEF) {
        const ownerSuggestion = this.suggestions['owner'].find((o: any) => owner.USEF == o.USEF);
        if (ownerSuggestion) {
          this.getPersonSuggestions('owner', ownerSuggestion.uid);
          return;
        }
      }

      if (!this.ownerForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'owner', owner).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      // Manually add to suggestions and select
      const newOwner = { ...owner, uid: documentId };
      if (!this.suggestions.owner) this.suggestions.owner = []; // Ensure array exists
      this.suggestions.owner.push(newOwner);
      this.selects.owner = documentId;
      $('#formModal').modal('hide');
      // this.getPersonSuggestions('owner', documentId); // REMOVE redundant call

    } else if (this.actions.modalForm == 'rider') {
      const rider = {
        USEF: this.riderForm.value.USEF || '',
        FEI: this.riderForm.value.FEI || '',
        firstname: this.riderForm.value.firstname || '',
        lastname: this.riderForm.value.lastname || '',
        address: this.riderForm.value.address || '',
        address2: this.riderForm.value.address2 || '',
        phonenumber: this.riderForm.value.phonenumber || '',
        city: this.riderForm.value.city || '',
        state: this.riderForm.value.state || '',
        zip: this.riderForm.value.zip || '',
        country: this.riderForm.value.country || '',
        EC: this.riderForm.value.EC || '',
      };
      if (rider.USEF) {
        const riderSuggestion = this.suggestions['rider'].find((r: any) => rider.USEF == r.USEF);
        if (riderSuggestion) {
          this.getPersonSuggestions('rider', riderSuggestion.uid);
          return;
        }
      }

      if (!this.riderForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'rider', rider).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      
      // Create the complete new rider object with uid
      const newRider = { 
        ...rider, 
        uid: documentId 
      };
      
      // Ensure suggestions.rider array exists
      if (!this.suggestions.rider) {
        this.suggestions.rider = [];
      }
      
      // Add to suggestions array
      this.suggestions.rider.push(newRider);
      
      // Log the new rider for debugging
      console.log('New rider created and added to suggestions:', newRider);
      
      // Add the rider ID to selected riders
      this.addRider(documentId);
      
      // Manually update selectedRiderDetails to ensure the rider is available
      this.updateSelectedRiderDetails();
      
      // Close the modal
      $('#formModal').modal('hide');

    } else if (this.actions.modalForm == 'trainer') {
      const trainer = {
        USEF: this.trainerForm.value.USEF || '',
        EC: this.trainerForm.value.EC || '',
        firstname: this.trainerForm.value.firstname || '',
        lastname: this.trainerForm.value.lastname || '',
        address: this.trainerForm.value.address || '',
        address2: this.trainerForm.value.address2 || '',
        phonenumber: this.trainerForm.value.phonenumber || '',
        city: this.trainerForm.value.city || '',
        state: this.trainerForm.value.state || '',
        zip: this.trainerForm.value.zip || '',
        country: this.trainerForm.value.country || ''
      };
      if (trainer.USEF) {
        const trainerSuggestion = this.suggestions['trainer'].find((t: any) => trainer.USEF == t.USEF);
        if (trainerSuggestion) {
          this.getPersonSuggestions('trainer', trainerSuggestion.uid);
          return;
        }
      }
      if (!this.trainerForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'trainer', trainer).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      // Manually add to suggestions and select
      const newTrainer = { ...trainer, uid: documentId };
      if (!this.suggestions.trainer) this.suggestions.trainer = []; // Ensure array exists
      this.suggestions.trainer.push(newTrainer);
      this.selects.trainer = documentId;
      $('#formModal').modal('hide');
      // this.getPersonSuggestions('trainer', documentId); // REMOVE redundant call

    } else if (this.actions.modalForm == 'payee') {
      const payee = {
        USEF: this.payeeForm.value.USEF || '',
        firstname: this.payeeForm.value.firstname || '',
        lastname: this.payeeForm.value.lastname || '',
        address: this.payeeForm.value.address || '',
        address2: this.payeeForm.value.address2 || '',
        phonenumber: this.payeeForm.value.phonenumber || '',
        city: this.payeeForm.value.city || '',
        state: this.payeeForm.value.state || '',
        zip: this.payeeForm.value.zip || '',
        country: this.payeeForm.value.country || '',
        EC: this.payeeForm.value.EC || '',
      };

      if (payee.USEF) {
        const payeeSuggestion = this.suggestions['payee'].find((t: any) => payee.USEF == t.USEF);
        if (payeeSuggestion) {
          this.getPersonSuggestions('payee', payeeSuggestion.uid);
          return;
        }
      }

      if (!this.payeeForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'payee', payee).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      // Manually add to suggestions and select
      const newPayee = { ...payee, uid: documentId };
      if (!this.suggestions.payee) this.suggestions.payee = []; // Ensure array exists
      this.suggestions.payee.push(newPayee);
      this.selects.payee = documentId;
      $('#formModal').modal('hide');
      // this.getPersonSuggestions('payee', documentId); // REMOVE redundant call
    } else {
      return;
    }
  }

  public newEntity(entity: string) {
    this.trainerForm.reset();
    this.riderForm.reset();
    this.ownerForm.reset();
    this.payeeForm.reset();
    this.horseForm.reset();
    this.actions.modalForm = entity;
    $('form').removeClass('was-validated');
    $('#formModal').modal('show');
  }

  /**
   * Esta funcion se ejecuta al cambiar el valor del checkbox de terms and conditions pero no hace nada como tal
   */
  TermsAndConditionsAggrement() {
    //Boolean this.termsAndConditions
    console.log('Terms and conditions changed:', this.termsAndConditions);
  }

  /**
   * Funcion que se ejecuta al comezar a dibujar en el canvas de firma
   */
  drawStart(entity: string) {
      console.log('Draw start for:', entity);
  }

  /**
   * Funcion que se ejecuta al terminar de dibujar en el canvas de firma
   */
  drawComplete(entity: string) {
      console.log('Draw complete for:', entity);
  }

  /**
   * Funcion que se ejecuta al limpiar el canvas de firma
   */
  clearDraw(entity: string, riderId: string = '') {
    console.log('Clear draw for:', entity, 'Rider ID:', riderId);
    let padsToClear: SignaturePad[] = [];

    if (entity === 'rider' && riderId) {
      padsToClear = this.signaturePads.toArray().filter((sp: any) => 
        sp.elementRef.nativeElement.classList.contains('rider') && 
        sp.elementRef.nativeElement.id === riderId
      );
    } else if (entity === 'trainer') {
       padsToClear = this.signaturePads.toArray().filter((sp: any) => 
        sp.elementRef.nativeElement.classList.contains('trainer')
      );
    } else if (entity === 'owner') {
       padsToClear = this.signaturePads.toArray().filter((sp: any) => 
        sp.elementRef.nativeElement.classList.contains('owner')
      );
    } else if (entity === 'responsible') {
       padsToClear = this.signaturePads.toArray().filter((sp: any) => 
        sp.elementRef.nativeElement.classList.contains('responsible')
      );
    }

    for(const signaturePad of padsToClear){
      signaturePad.clear();
    }
  }

  // --- RESTORE Helper Function ---
  // Helper to get quantity for Additional Items input binding
  public getSelectedConceptoQuantity(conceptoId: string | number): number { // Accept number or string for ID
    if (!this.conceptosSeleccionados) {
      return 0;
    }
    // Ensure comparison is done correctly (e.g., convert both to string or number if types might mismatch)
    const selected = this.conceptosSeleccionados.find(c => String(c.idConcepto) === String(conceptoId));
    // Ensure qty is parsed as a number
    return selected ? parseInt(selected.qty, 10) || 0 : 0;
  }
  // --- END RESTORE ---

  // Enhanced method to update the selectedRiderDetails array with better error handling
  private updateSelectedRiderDetails(): void {
    console.log('[updateSelectedRiderDetails] Updating based on selects.riders:', this.selects.riders);
    
    // Safety check for null/undefined
    if (!this.selects.riders || !Array.isArray(this.selects.riders)) {
      console.warn('[updateSelectedRiderDetails] selects.riders is not a valid array');
      this.selectedRiderDetails = [];
      return;
    }
    
    this.selectedRiderDetails = this.selects.riders.map((riderId: string) => {
      // Get the rider details using existing method
      const details = this.getRiderDetails(riderId);
      
      // Ensure the UID is always included in the result
      if (details && typeof details === 'object') {
        details.uid = riderId; // Always ensure UID is set correctly
        return details;
      } else {
        // Fallback if details is null or not an object
        return {
          uid: riderId,
          firstname: "Not Found",
          lastname: "",
          USEF: "",
          EC: ""
        };
      }
    }).filter((r: any) => !!r); // Filter out any null/undefined values
    
    console.log('[updateSelectedRiderDetails] Updated array:', this.selectedRiderDetails);
  }

  // New method to update the selectedClasses array
  private updateSelectedClasses(): void {
    console.log('[updateSelectedClasses] Updating based on discipline:', this.discipline);
    if (this.discipline && this.discipline !== 'Non_showing') {
      this.getDivisionsByType();
      this.entry.classes = this.selectedClasses[this.discipline] || [];
    } else {
      this.entry.classes = [];
    }
    console.log('[updateSelectedClasses] Result:', this.entry.classes);
  }

  // --- Re-add Class Selection Methods --- 

  public toggleClassSelection(prueba: any) {
    console.log('=== toggleClassSelection called ===');
    console.log('Current prueba:', prueba);
    console.log('Current discipline:', this.currentDiscipline);
    console.log('Current selectedClasses before toggle:', JSON.parse(JSON.stringify(this.selectedClasses)));
    
    prueba.selected = !prueba.selected;
    
    // Update the selected classes for the current discipline
    if (!this.selectedClasses[this.currentDiscipline]) {
      this.selectedClasses[this.currentDiscipline] = [];
    }
    
    if (prueba.selected) {
      // Add if not already present (shouldn't happen with proper state, but safe check)
      if (!this.selectedClasses[this.currentDiscipline].some(c => c.ipc === prueba.ipc)) {
           this.selectedClasses[this.currentDiscipline].push(prueba);
           console.log('Adding prueba to selected classes:', prueba.ipc);
      }
    } else {
      // Remove
      this.selectedClasses[this.currentDiscipline] = this.selectedClasses[this.currentDiscipline]
        .filter((c: any) => c.ipc !== prueba.ipc);
      console.log('Removing prueba from selected classes:', prueba.ipc);
    }
    
    // Update the main entry.classes (which should store IPCs for saving)
    this.updateEntryClassesFromSelected(); 
    console.log('Final selectedClasses state after toggle:', JSON.parse(JSON.stringify(this.selectedClasses)));
  }

  // Method to update the flat list of IPCs in this.entry.classes for saving
  private updateEntryClassesFromSelected(): void {
    this.entry.classes = Object.values(this.selectedClasses).flat().map(c => c.ipc);
    console.log('Updated entry.classes (IPCs for saving):', this.entry.classes);
  }

  // --- End Re-add Class Selection Methods ---
}
